import { r, createSender, baseResource } from '@md/resources';
import { modelPrefix } from '@md/api.js';

import {
  friendlyUrlGenerator,
} from '@aspectus/vue-friendly';

const MODEL = '/touroperator';
const MODULE = url => modelPrefix(url, MODEL);

const FILTERS_LIST_URL_POSTFIX = '/ajax/filters/';

const order = {
  path: [
    'prefix',
    'label',
    'category',
    'postfix',
    'filters',
  ],
  query: [
    'pagination_offset',
  ],
};

export const touroperatorsList =
  friendlyUrlGenerator([MODEL, FILTERS_LIST_URL_POSTFIX], baseResource, order);

export const touroperators = {
  list: r(MODULE('/list/{?filters,limit,offset}')),
  create: r(MODULE('/create/'), createSender),
  update: r(MODULE('{/id}/update/'), createSender).config('method', 'PATCH'),
  delete: r(MODULE('{/id}/delete/'), createSender).config('method', 'PATCH'),
  extendedRetrieve: r(MODULE('{/id}/extended/retrieve/')),
  baseRetrieve: r(MODULE('{/id}/base/retrieve/')),
  addons: {
    tourApplicationCosts: r(MODULE('/addons/tour-application-cost/list/{?s,filters,limit,offset}')),
    tourTypes: r(MODULE('/addons/tour-types/list/{?s,filters}')),
  },
  balance: r(MODULE('{/id}/balance/retrieve/')),
  tourBonusValues: r(MODULE('/tour-bonus-values/list/{?s}')),
  search: r(MODULE('/search/{?s}')),
  retrieveOrder: r(MODULE('/order/retrieve/{?filters}')),
};

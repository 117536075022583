import { route } from '@md/routing';

const List = () => import('./views/List');

export const AGENT_HOTELS_BOOKING_LIST = 'agent:hotels-booking:list';

export function createRoutes() {
  return [
    route('list/', List, AGENT_HOTELS_BOOKING_LIST),
    { path: '', redirect: { name: AGENT_HOTELS_BOOKING_LIST } },
  ];
}

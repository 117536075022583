import { prefixRoutes, route } from '@md/routing';

import { createRoutes as createApplicationsRoutes } from './modules/applications/router.js';
import { createRoutes as createBookingRoutes } from './modules/booking/router.js';
import { createRoutes as createProfileRoutes } from './modules/profile/router.js';
import { createRoutes as createAppealsRoutes } from './modules/appeals/router.js';
import { createRoutes as createNightsRoutes } from './modules/nights-stock/router.js';
import { createRoutes as createPromotionsRoutes } from './modules/promotions/router.js';
import { createRoutes as createBalanceRoutes } from './modules/balance/router.js';
import { createRoutes as createFaqRoutes } from './modules/faq/router.js';
import { createRoutes as createChatsRoutes } from './modules/chats/router.js';
import { createRoutes as createNotificationsRoutes } from './modules/notifications/router.js';
import { createRoutes as createReferralsRoutes } from './modules/referrals/router.js';
import { createRoutes as createHotelsBookingRoutes } from './modules/hotels-booking/router.js';

const Home = () => import('./modules/home/View.vue');
const Route404 = () => import('@sections/404.vue');

export const AGENT_HOME = 'agent:home';

export function createRoutes(options) {
  return [
    route('home/', Home, AGENT_HOME),
    prefixRoutes('applications/', createApplicationsRoutes(options), {
      // meta: accessPermission(APPLICATIONS_LIST_VIEW, options),
    }),
    prefixRoutes('booking/', createBookingRoutes(options), {}),
    prefixRoutes('profile/', createProfileRoutes(options), {
    }),
    prefixRoutes('appeals/', createAppealsRoutes(options), {
    }),
    prefixRoutes('faq/', createFaqRoutes(options)),
    prefixRoutes('chats/', createChatsRoutes(options)),
    prefixRoutes('notifications/', createNotificationsRoutes(options)),
    prefixRoutes('promotions/', createPromotionsRoutes(options)),
    prefixRoutes('referrals/', createReferralsRoutes()),
    prefixRoutes('nights/', createNightsRoutes(options), {
    }),

    prefixRoutes('balance/', createBalanceRoutes(options), {
    }),
    prefixRoutes('hotels-booking/', createHotelsBookingRoutes(options), {
    }),

    { path: '', redirect: { name: AGENT_HOME } },
    { path: '*', component: Route404 },
  ];
}

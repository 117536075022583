// role
const AGENT = 'agent';
const HOTELIER = 'hotelier';
const HOTELIER_CHAIN = 'hotelierchain';
const DMC = 'dmc';
const TOUROPERATOR = 'touroperator';
const REPRESENTATIVE = 'representative';

export const userTypeHintCabinetMap = {
  '0001000-agent': AGENT,
  '0002000-hotelier': HOTELIER,
  '0002010-hotelier-chain': HOTELIER_CHAIN,
  '0002100-dmc': DMC,
  '0003100-touroperator': TOUROPERATOR,
  '0003100-representative': REPRESENTATIVE,
};

const TITLE_KEY = 'title';

// Pages

// Hotelier pages
const START_PAGE = 'startPage';
const HOTEL_PROFILE = 'hotelProfile';
const CREATE_HOTEL = 'createHotel';
const ROOMS = 'rooms';
const ROOM_PROFILE = 'roomProfile';
const BONUS_PROGRAM = 'bonusProgram';
const BONUS_BASIC_CONDITION = 'bonusBasicCondition';
const BONUS_GROUPS = 'bonusGroups';
const BONUS_RULES = 'bonusRules';
const BONUS_PROGRAM_HOTEL = 'bonusProgramHotel';
const APPLICATIONS_LIST = 'applicationsList';
const BOOKING_LIST = 'bookingList';
const REVIEWS = 'reviews';
const NOTIFICATIONS = 'notifications';
const CHAT = 'chat';
const TRANSACTIONS = 'transactions';
const PROFILE = 'profile';
const MANAGERS = 'managers';

// Agent pages
const CREATE_APPLICATION = 'createApplication';
const PARTICIPATING_HOTELS = 'participatingHotels';
const CREATE_BOOKING = 'createBooking';
const BALANCE = 'balance';
const FROZEN_PAYMENTS = 'frozenPayments';
const REFERRALS = 'referrals';
const MODALS = 'modals';
const PAYOUT = 'payout';

// Hotelier blocks
export const H_START_PAGE_TITLE = `${HOTELIER}:${START_PAGE}:title`;
export const H_HOTEL_PROFILE_STATUS = `${HOTELIER}:${HOTEL_PROFILE}:status`;
export const H_CREATE_HOTEL_TITLE = `${HOTELIER}:${CREATE_HOTEL}:title`;
export const H_ROOMS_TITLE = `${HOTELIER}:${ROOMS}:${TITLE_KEY}`;
export const H_ROOM_PROFILE_STATUS = `${HOTELIER}:${ROOM_PROFILE}:status`;
export const H_BONUS_PROGRAM_TITLE = `${HOTELIER}:${BONUS_PROGRAM}:${TITLE_KEY}`;
export const H_BONUS_PROGRAM_HOTEL_TITLE = `${HOTELIER}:${BONUS_PROGRAM_HOTEL}:${TITLE_KEY}`;
export const H_APPLICATIONS_LIST_TITLE = `${HOTELIER}:${APPLICATIONS_LIST}:${TITLE_KEY}`;
export const H_BOOKING_LIST_TITLE = `${HOTELIER}:${BOOKING_LIST}:${TITLE_KEY}`;
export const H_REVIEWS_TITLE = `${HOTELIER}:${REVIEWS}:${TITLE_KEY}`;
export const H_NOTIFICATIONS_TITLE = `${HOTELIER}:${NOTIFICATIONS}:${TITLE_KEY}`;
export const H_CHAT_TITLE = `${HOTELIER}:${CHAT}:${TITLE_KEY}`;
export const H_TRANSACTIONS = `${HOTELIER}:${TRANSACTIONS}:${TITLE_KEY}`;
export const H_PROFILE_TITLE = `${HOTELIER}:${PROFILE}:${TITLE_KEY}`;
export const H_MANAGERS = `${HOTELIER}:${MANAGERS}:${TITLE_KEY}`;

// Hotelier chain blocks
export const HC_START_PAGE_TITLE = `${HOTELIER_CHAIN}:${START_PAGE}:title`;
export const HC_HOTEL_PROFILE_STATUS = `${HOTELIER_CHAIN}:${HOTEL_PROFILE}:status`;
export const HC_CREATE_HOTEL_TITLE = `${HOTELIER_CHAIN}:${CREATE_HOTEL}:title`;
export const HC_ROOMS_TITLE = `${HOTELIER_CHAIN}:${ROOMS}:${TITLE_KEY}`;
export const HC_ROOM_PROFILE_STATUS = `${HOTELIER_CHAIN}:${ROOM_PROFILE}:status`;
export const HC_BONUS_PROGRAM_TITLE = `${HOTELIER_CHAIN}:${BONUS_PROGRAM}:${TITLE_KEY}`;
export const HC_BONUS_PROGRAM_HOTEL_TITLE = `${HOTELIER_CHAIN}:${BONUS_PROGRAM_HOTEL}:${TITLE_KEY}`;
export const HC_APPLICATIONS_LIST_TITLE = `${HOTELIER_CHAIN}:${APPLICATIONS_LIST}:${TITLE_KEY}`;
export const HC_BOOKING_LIST_TITLE = `${HOTELIER_CHAIN}:${BOOKING_LIST}:${TITLE_KEY}`;
export const HC_REVIEWS_TITLE = `${HOTELIER_CHAIN}:${REVIEWS}:${TITLE_KEY}`;
export const HC_NOTIFICATIONS_TITLE = `${HOTELIER_CHAIN}:${NOTIFICATIONS}:${TITLE_KEY}`;
export const HC_CHAT_TITLE = `${HOTELIER_CHAIN}:${CHAT}:${TITLE_KEY}`;
export const HC_TRANSACTIONS = `${HOTELIER_CHAIN}:${TRANSACTIONS}:${TITLE_KEY}`;
export const HC_PROFILE_TITLE = `${HOTELIER_CHAIN}:${PROFILE}:${TITLE_KEY}`;
export const HC_MANAGERS = `${HOTELIER_CHAIN}:${MANAGERS}:${TITLE_KEY}`;
export const HC_BONUS_GROUPS_TITLE = `${HOTELIER_CHAIN}:${BONUS_GROUPS}:${TITLE_KEY}`;

// DMC blocks
export const D_START_PAGE_TITLE = `${DMC}:${START_PAGE}:title`;
export const D_HOTEL_PROFILE_STATUS = `${DMC}:${HOTEL_PROFILE}:status`;
export const D_ROOMS_TITLE = `${DMC}:${ROOMS}:${TITLE_KEY}`;
export const D_ROOM_PROFILE_STATUS = `${DMC}:${ROOM_PROFILE}:status`;
export const D_BONUS_PROGRAM_TITLE = `${DMC}:${BONUS_PROGRAM}:${TITLE_KEY}`;
export const D_BONUS_BASIC_CONDITION_TITLE = `${DMC}:${BONUS_BASIC_CONDITION}:${TITLE_KEY}`;
export const D_BONUS_GROUPS_TITLE = `${DMC}:${BONUS_GROUPS}:${TITLE_KEY}`;
export const D_BONUS_RULES_TITLE = `${DMC}:${BONUS_RULES}:${TITLE_KEY}`;
export const D_BONUS_PROGRAM_HOTEL_TITLE = `${DMC}:${BONUS_PROGRAM_HOTEL}:${TITLE_KEY}`;
export const D_PARTICIPATING_HOTELS_TITLE = `${DMC}:${PARTICIPATING_HOTELS}:${TITLE_KEY}`;
export const D_APPLICATIONS_LIST_TITLE = `${DMC}:${APPLICATIONS_LIST}:${TITLE_KEY}`;
export const D_BOOKING_LIST_TITLE = `${DMC}:${BOOKING_LIST}:${TITLE_KEY}`;
export const D_REVIEWS_TITLE = `${DMC}:${REVIEWS}:${TITLE_KEY}`;
export const D_NOTIFICATIONS_TITLE = `${DMC}:${NOTIFICATIONS}:${TITLE_KEY}`;
export const D_CHAT_TITLE = `${DMC}:${CHAT}:${TITLE_KEY}`;
export const D_TRANSACTIONS = `${DMC}:${TRANSACTIONS}:${TITLE_KEY}`;
export const D_PROFILE_TITLE = `${DMC}:${PROFILE}:${TITLE_KEY}`;

// Agent block
export const A_START_PAGE_AGENT_ID = `${AGENT}:${START_PAGE}:agentId`;
export const A_APPLICATIONS_LIST_TITLE = `${AGENT}:${APPLICATIONS_LIST}:${TITLE_KEY}`;
export const A_CREATE_APPLICATION_TITLE = `${AGENT}:${CREATE_APPLICATION}:${TITLE_KEY}`;
export const A_PARTICIPATING_HOTELS_TITLE = `${AGENT}:${PARTICIPATING_HOTELS}:${TITLE_KEY}`;
export const A_BOOKING_LIST_TITLE = `${AGENT}:${BOOKING_LIST}:${TITLE_KEY}`;
export const A_CREATE_BOOKING_TITLE = `${AGENT}:${CREATE_BOOKING}:${TITLE_KEY}`;
export const A_CHAT_TITLE = `${AGENT}:${CHAT}:${TITLE_KEY}`;
export const A_NOTIFICATIONS_TITLE = `${AGENT}:${NOTIFICATIONS}:${TITLE_KEY}`;
export const A_BALANCE_TITLE = `${AGENT}:${BALANCE}:${TITLE_KEY}`;
export const A_FROZEN_PAYMENTS_TITLE = `${AGENT}:${FROZEN_PAYMENTS}:${TITLE_KEY}`;
export const A_TRANSACTIONS_TITLE = `${AGENT}:${TRANSACTIONS}:${TITLE_KEY}`;
export const A_REFERRALS_TITLE = `${AGENT}:${REFERRALS}:${TITLE_KEY}`;
export const A_PROFILE_TITLE = `${AGENT}:${PROFILE}:${TITLE_KEY}`;
export const A_TRANSFER_MODAL_HINT = `${AGENT}:${MODALS}:${TITLE_KEY}`;
export const A_PAYOUT_HINT = `${AGENT}:${PAYOUT}:${TITLE_KEY}`;

// Representative block
export const R_START_PAGE_TITLE = `${REPRESENTATIVE}:${START_PAGE}:${TITLE_KEY}`;
export const R_REFERRALS_PAGE_TITLE = `${REPRESENTATIVE}:${REFERRALS}:${TITLE_KEY}`;
export const R_FROZEN_PAYMENTS_PAGE_TITLE = `${REPRESENTATIVE}:${FROZEN_PAYMENTS}:${TITLE_KEY}`;
export const R_CHAT_TITLE = `${REPRESENTATIVE}:${CHAT}:${TITLE_KEY}`;
export const R_NOTIFICATIONS_TITLE = `${REPRESENTATIVE}:${NOTIFICATIONS}:${TITLE_KEY}`;
export const R_TRANSACTIONS_TITLE = `${REPRESENTATIVE}:${TRANSACTIONS}:${TITLE_KEY}`;
export const R_PROFILE_TITLE = `${REPRESENTATIVE}:${PROFILE}:${TITLE_KEY}`;
export const R_CREATE_HOTEL_TITLE = `${REPRESENTATIVE}:${CREATE_HOTEL}:${TITLE_KEY}`;

// Touroperator blocks
export const T_START_PAGE_TITLE = `${TOUROPERATOR}:${START_PAGE}:title`;
export const T_HOTEL_PROFILE_STATUS = `${TOUROPERATOR}:${HOTEL_PROFILE}:status`;
export const T_ROOMS_TITLE = `${TOUROPERATOR}:${ROOMS}:${TITLE_KEY}`;
export const T_ROOM_PROFILE_STATUS = `${TOUROPERATOR}:${ROOM_PROFILE}:status`;
export const T_BONUS_PROGRAM_TITLE = `${TOUROPERATOR}:${BONUS_PROGRAM}:${TITLE_KEY}`;
export const T_BONUS_BASIC_CONDITION_TITLE = `${TOUROPERATOR}:${BONUS_BASIC_CONDITION}:${TITLE_KEY}`;
export const T_BONUS_GROUPS_TITLE = `${TOUROPERATOR}:${BONUS_GROUPS}:${TITLE_KEY}`;
export const T_BONUS_RULES_TITLE = `${TOUROPERATOR}:${BONUS_RULES}:${TITLE_KEY}`;
export const T_BONUS_PROGRAM_HOTEL_TITLE = `${TOUROPERATOR}:${BONUS_PROGRAM_HOTEL}:${TITLE_KEY}`;
export const T_PARTICIPATING_HOTELS_TITLE = `${TOUROPERATOR}:${PARTICIPATING_HOTELS}:${TITLE_KEY}`;
export const T_APPLICATIONS_LIST_TITLE = `${TOUROPERATOR}:${APPLICATIONS_LIST}:${TITLE_KEY}`;
export const T_BOOKING_LIST_TITLE = `${TOUROPERATOR}:${BOOKING_LIST}:${TITLE_KEY}`;
export const T_REVIEWS_TITLE = `${TOUROPERATOR}:${REVIEWS}:${TITLE_KEY}`;
export const T_NOTIFICATIONS_TITLE = `${TOUROPERATOR}:${NOTIFICATIONS}:${TITLE_KEY}`;
export const T_CHAT_TITLE = `${TOUROPERATOR}:${CHAT}:${TITLE_KEY}`;
export const T_TRANSACTIONS = `${TOUROPERATOR}:${TRANSACTIONS}:${TITLE_KEY}`;
export const T_PROFILE_TITLE = `${TOUROPERATOR}:${PROFILE}:${TITLE_KEY}`;

import {
  defaultDataTransformer,
  jsonTransformer,
} from '@aspectus/resource-commons';
import { prefixAPI, urlGetter } from '@md/api.js';
import { prefixLanguage } from '@md/urls';
import {
  baseResource,
  createReceiver,
  createSender,
} from '@md/resources';

const r = (tpl, f = createReceiver) => f(prefixAPI(tpl)).transform(defaultDataTransformer);

const AGENTS_ITEMS_PREFIX = prefixLanguage('/agents/ajax/filters/');
// const AGENTS_ATTRIBUTES_PREFIX = prefixAPI('/agents/attributes/list/filters/');

export const agentsListUrlGetter = urlGetter(AGENTS_ITEMS_PREFIX);
// export const agentsAttributesUrlGetter = urlGetter(AGENTS_ATTRIBUTES_PREFIX);
export const ajax = baseResource.url(agentsListUrlGetter)
  .transform(jsonTransformer)
  .transform(defaultDataTransformer);
// export const attributes = baseResource.url(agentsAttributesUrlGetter)
//   .transform(jsonTransformer)
//   .transform(defaultDataTransformer);

export const agents = {
  ajax,
  // ajax: createReceiver(prefixLanguage('/agents/ajax/filters/{filters*}/{?limit,offset}'))
  //   .transform(defaultDataTransformer),
  // attributes,
  // create: r('/agents/create/', createSender),
  // addons: {
  //   tags: {
  //     list: r('/agents/addons/tags/list/{?limit,offset}'),
  //   },
  // },
  // list: r('/agents/list/{?filters,order_by,limit,offset}'),
  // filters: {
  //   list: r('/agents/filters/list/'),
  //   available: r('/agents/filters/list/{?filters}'),
  // },
  // aggregate: r('/agents/aggregate/{?filters}'),
  // retrieve: r('/agents{/id}/retrieve/'),
  // delete: r('/agents{/id}/delete/', createSender).config('method', 'DELETE'),
  // share: r('/agents{/id}/share/', createSender),
  search: r('/agents/catalog/search/{?s,limit,offset,obj_id,email}'),
  getItTourID: r('/agents/catalog/get_ittour_id/', createSender),
  touroperatorCredentials: {
    create: r('/agents/touroperator_credentials/create/', createSender),
    delete: r('/agents/touroperator_credentials{/id}/delete/', createSender).config('method', 'DELETE'),
  },
};

import {
  defaultDataTransformer,
  jsonTransformer,
} from '@aspectus/resource-commons';
import { prefixAPI, urlGetter } from '@md/api.js';
import { prefixLanguage } from '@md/urls';
import {
  baseResource,
  createReceiver,
  createSender,
} from '@md/resources/index.js';

const r = (tpl, f = createReceiver) => f(prefixAPI(tpl)).transform(defaultDataTransformer);

const HOTELS_ITEMS_PREFIX = prefixLanguage('/hotels/ajax/filters/');
const HOTELS_ATTRIBUTES_PREFIX = prefixAPI('/hotels/catalog/filters/list/');
export const hotelsListUrlGetter = urlGetter(HOTELS_ITEMS_PREFIX);
export const hotelsAttributesUrlGetter = urlGetter(HOTELS_ATTRIBUTES_PREFIX);

export const hotelsAjax = baseResource.url(hotelsListUrlGetter)
  .transform(jsonTransformer)
  .transform(defaultDataTransformer);

export const hotels = {
  hotelsAjax,
  catalogFilters: {
    list: r('/hotels/catalog/filters/list/'),
    available: r('/hotels/catalog/filters/list/{?filters}'),
  },
  favourite: {
    add: r('/hotels/favourite/add/', createSender),
    remove: r('/hotels/favourite/remove/', createSender),
  },
  fullness: r('/hotels{/id}/fullness/'),
  cabinetList: r('/hotels/cabinet/list/{?filters,order_by,limit,offset}'),
  previewList: r('/hotels/preview/list/{?filters,limit,offset}'),
  publicList: r('/hotels/public/list/{?filters,limit,offset}'),
  addons: {
    documents: {
      create: r('/hotels/addons/documents/create/', createSender),
      delete: r('/hotels/addons/documents{/id}/delete/', createSender).config('method', 'DELETE'),
      list: r('/hotels/addons/documents/list/{?filters,limit,offset}'),
    },
    types: r('/hotels/addons/types/list/{?limit}'),
    deleteReasons: r('/hotels/addons/delete-reasons/list/'),
    security: {
      types: r('/hotels/addons/security/types/list/{?limit}'),
      retrieve: r('/hotels/addons/security{/id}/retrieve/'),
      update: r('/hotels/addons/security{/id}/update/', createSender).config('method', 'PATCH'),
    },
    attributes: r('/hotels/addons/attributes/list/{?filters}'),
  },
  create: r('/hotels/create/', createSender),
  base: {
    list: r('/hotels/base/list/{?filters,order_by,limit,offset,loyalty_program,hotel_group,country}'),
    retrieve: r('/hotels/base{/id}/retrieve/'),
  },
  galleryList: r('/hotels{/id}/image-gallery/'),
  extendedRetrieve: r('/hotels/extended{/id}/retrieve/'),
  permissions: r('/hotels{/id}/permissions/'),
  update: r('/hotels{/id}/update/', createSender).config('method', 'PATCH'),
  delete: r('/hotels{/id}/delete/', createSender).config('method', 'PATCH'),
  search: r('/hotels/search/{?s}'),
  hotelSearch: r('/hotels/hotel_search/{?filters,search}'),
  dictionaries: {
    list: r('/hotels/dictionaries/list/{?filters,limit,offset,search}'),
    create: r('/hotels/create-from-dictionary/', createSender),
  },
  groups: {
    list: r('/hotels/hotel-group/list/'),
  },
  markets: {
    list: r('/hotels{/id}/markets/list/'),
  },
  adminCabinetFilters: {
    list: r('/hotels/admincabinet/filters/'),
    available: r('/hotels/admincabinet/filters/{?filters}'),
  },
  adminCabinetList: r('/hotels/admincabinet/list/{?filters,order_by,limit,offset}'),
  referrals: {
    list: r('/hotels/referrals/list/{?filters}'),
  },
};

export const roomTypes = {
  addons: {
    allocations: r('/room_types/addons/allocations/list/{?s,hotel,filters,limit}'),
  },
  create: r('/room_types/create/', createSender),
  list: r('/room_types/list/{?filters,limit}'),
  update: r('/room_types{/id}/update/', createSender).config('method', 'PATCH'),
  delete: r('/room_types{/id}/delete/', createSender).config('method', 'DELETE'),
};

export const bonusGroups = {
  list: r('/bonus-groups/list/{?filters,limit}'),
  create: r('/bonus-groups/create/', createSender),
  update: r('/bonus-groups{/id}/update/', createSender).config('method', 'PUT'),
  delete: r('/bonus-groups{/id}/delete/', createSender).config('method', 'DELETE'),
  updateRooms: r('/bonus-groups{/id}/update-rooms/', createSender).config('method', 'PATCH'),
};

export const rooms = {
  baseList: r('/rooms/base/list/{?filters,limit,offset}'),
  listForAgent: r('/rooms/base/list-for-agent/{?filters,limit,offset}'),
  list: r('/rooms/list/{?filters,limit,offset}'),
  group_by_hotel_list: r('/rooms/group-by-hotel-list/{?filters,limit,offset}'),
  filters: {
    list: r('/rooms/filters/list/'),
    available: r('/rooms/filters/list/{?filters}'),
    group_by_hotel_list: r('/rooms/filters/group-by-hotel-list/'),
    group_by_hotel_available: r('/rooms/filters/group-by-hotel-list/{?filters}'),
  },
  publicList: r('/rooms/public/list/{?filters,limit,offset}'),
  // filters: {
  //   list: r('/rooms/filters/list/'),
  //   available: r('/rooms/filters/list/{?filters}'),
  // },
  retrieve: r('/rooms{/id}/retrieve/'),
  create: r('/rooms/create/', createSender),
  update: r('/rooms{/id}/update/', createSender).config('method', 'PATCH'),
  delete: r('/rooms{/id}/delete/', createSender).config('method', 'DELETE'),
  create_3d_tour: r('/rooms{/id}/create-3d-tour/', createSender).config('method', 'POST'),
  delete_3d_tour: r('/rooms{/id}/delete-3d-tour/', createSender).config('method', 'DELETE'),
};

export const managers = {
  addons: {
    permissions: r('/managers/addons/permissions/list/'),
  },
  create: r('/managers/create/', createSender),
  confirm: r('/managers/confirm/', createSender),
  list: r('/managers/list/{?managed_hotels__status,managed_hotels__hotel,order_by,limit,offset}'),
  newUser: r('/managers/new-user/confirm/', createSender),
  update: r('/managers{/id}/update/', createSender)
    .config('method', 'PATCH'),
  delete: r('/managers{/id}/delete/', createSender)
    .config('method', 'DELETE'),
  cancel: r('/managers{/id}/cancel/', createSender)
    .config('method', 'DELETE'),
  refuse: r('/managers{/id}/refuse/', createSender)
    .config('method', 'DELETE'),
  send: r('/managers{/id}/send/', createSender),
};

export const attributes = {
  globalList: r('/attributes/global/list/{?filters,limit,offset}'),
  categoriesList: r('/attributes/categories/list/{?filters,limit,offset}'),
  list: r('/attributes/list/{?filters,limit,offset}'),
  extendedList: r('/attributes/extended/list/{?filters,limit,offset}'),
  valuesList: r('/attributes/values/list/{?s,filters,limit,offset}'),
  createValue: r('/attributes/values/create/', createSender),
  listAttributeValuePhoto: r('/attributes/values/list-photo/{?filters}'),
  addAttributeValuePhoto: r('/attributes/values/add-photo/', createSender),
  removeAttributeValuePhoto: r('/attributes/values{/id}/delete-photo/', createSender).config('method', 'DELETE'),
};

export const services = {
  create: r('/hotel-services/create/', createSender),
  update: r('/hotel-services{/id}/update/', createSender).config('method', 'PATCH'),
  extendedRetrieve: r('/hotel-services{/id}/extended/retrieve/'),
  publicRetrieve: r('/hotel-services{/id}/public/retrieve/'),
  list: r('/hotel-services/list/{?filters,limit,offset}'),
  delete: r('/hotel-services{/id}/delete/', createSender).config('method', 'DELETE'),
};

export const sections = {
  create: r('/sections/create/', createSender),
  update: r('/sections{/id}/update/', createSender).config('method', 'PATCH'),
  retrieve: r('/sections{/id}/retrieve/'),
  delete: r('/sections{/id}/delete/', createSender).config('method', 'DELETE'),
  list: r('/sections/list/{?filters,order_by,limit,offset}'),
  orderingUpdate: r('/sections/ordering/update/', createSender),
  publicList: r('/sections/public/list/{?filters,order_by,limit,offset}'),
};

export const roomTips = {
  create: r('/room_tips/create/', createSender),
  update: r('/room_tips{/id}/update/', createSender).config('method', 'PATCH'),
  delete: r('/room_tips{/id}/delete/', createSender).config('method', 'DELETE'),
  list: r('/room_tips/list/{?filters,limit}'),
};

import Pagination from './Pagination';
import Controller from './Controller';
import PageFiltering from './PageFiltering';

function install(Vue) {
  Vue.component(Pagination.name, Pagination);
  Vue.component(Controller.name, Controller);
  Vue.component(PageFiltering.name, PageFiltering);
}

export default { install };
